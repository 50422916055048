export const aldea = {
  red: '#ff0000',
  pink: '#ED005E',
  orange: '#F46A00',
  blue: '#4ABFE7',
  green: '#7DC421',
  greenpretty: '#66f446',
  yellow: '#ECD90B',
  greenalt: '#d1da4b',
  purple: '#954EA6',
};

export const aldeaList = Object.entries(aldea).map((arr) => ({ name: arr[0], value: arr[1] }));

export const general = {
  white: '#FFFFFF',
  black: '#000000',
  whiteAlpha: '#FFFFFF50',
  blackAlpha: '#00000050',
  darkGray: '#4A4A4A',
  lightGray: '#F4F4F4',
  whiteBluish: '#E2E8EC',
  whiteBluish2: '#D7E3E8',
  dustyGray: '#9B9B9B',
  borderGray: '#b5b5b5',
  borderGrayOpacity: '#97979750',
  iconGray: '#D5D5D5',
  lighterGray: '#E2E2E2',
  silver: '#CCCCCC',
  gold: '#F8E71C',
  trophyYellow: '#FFC200',
  error: '#D0021B',
  errorNeo: '#e46f89',
  warmWhite: '#F8F1F3',
  transparent: 'transparent',
  fakeMultiplayBlue: '#17364E',
  multiplyBlue: '#9cb5ca',
};

export const generalList = Object.entries(general).map((arr) => ({ name: arr[0], value: arr[1] }));

export const misc = {
  facebook: '#3b5998',
  excel: '#197C43',
  boy: '#1FADDF',
  girl: '#F14B8A',
  materials: '#F78E21',
  lavender: '#a29cff',
};

export const miscList = Object.entries(misc).map((arr) => ({ name: arr[0], value: arr[1] }));

export const neutral = {
  shadeLighter: '#F8F8F8',
  shadeLight: '#F0F0F0',
  shadeMediumLight: '#CFD5DA',
  shadeMedium: '#ACC2D3',
  shadeMediumDark: '#8295A3',
  shadeDark: '#657480',
  shadeDarker: '#2C343A',
};

export const neutralList = Object.entries(neutral).map((arr) => ({ name: arr[0], value: arr[1] }));

export const primary = {
  newKinedu: '#0086D8',
  newKineduLight: '#EAF5FC',
  kinedu: '#278ECD',
  oldkinedu: '#1B75BB',
  kineduShade: '#165E96',
  kineduShadeDark: '#134F7D',
};

export const primaryList = Object.entries(primary).map((arr) => ({ name: arr[0], value: arr[1] }));

export const secondary = {
  physicalDark50: '#085670',
  physicalDark20: '#0689B4',
  physical: '#32AFE0',
  physicalLight30: '#5DC5EA',
  physicalLight50: '#8CD6F0',
  physicalLight70: '#BBE6F6',
  physicalLight80: '#D9F5FF',
  cognitiveDark50: '#395C26',
  cognitiveDark20: '#5B933D',
  cognitive: '#3EB646',
  cognitiveLight30: '#9DCD83',
  cognitiveLight50: '#B9DCA7',
  cognitiveLight80: '#E9FFDF',
  socialEmotionalDark50: '#6A1838',
  socialEmotionalDark20: '#AB275A',
  socialEmotional: '#D1296D',
  socialEmotionalLight30: '#E26F9B',
  socialEmotionalLight50: '#EB99B8',
  socialEmotionalLight70: '#F3C1D4',
  linguisticDark50: '#7C4604',
  linguisticDark20: '#C77100',
  linguistic: '#F99848',
  linguisticLight30: '#FBB15D',
  linguisticLight50: '#FCC78C',
  linguisticLight70: '#FDDDBA',
  healthDark50: '#730000',
  healthDark20: '#B90000',
  health: '#E50000',
  healthLight30: '#EF4B48',
  healthLight50: '#F47F7E',
  healthLight70: '#F8B3B2',
};

export const secondaryList = Object.entries(secondary).map((arr) => ({ name: arr[0], value: arr[1] }));
