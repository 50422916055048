import React from 'react';
import './color-item.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ColorItem = ({ color, handleCopy }) => {
  return (
    <div className="color-item">
      <CopyToClipboard text={'${({ theme }) => theme.colors.' + color.name + '}'} onCopy={handleCopy}>
        <div className="color-sample" style={{ background: color.value }} />
      </CopyToClipboard>
      <CopyToClipboard text={color.name} onCopy={handleCopy}>
        <span className="color-name">{color.name}</span>
      </CopyToClipboard>
      <CopyToClipboard text={color.value} onCopy={handleCopy}>
        <span className="color-value">{color.value}</span>
      </CopyToClipboard>
    </div>
  );
};

export default ColorItem;
