/* Dependencies */
import React, { useState } from 'react';

/* Styles */
import './colors.css';

/* Components */
import Layout from '@components/layout';
import ColorItem from '../../components/ColorItem/ColorItem';
import { Transition } from 'semantic-ui-react';

/* Data */
import { primaryList, secondaryList, neutralList, generalList, miscList, aldeaList } from '../../data/colors';

export default function DesignTokensPage() {
  const [copyStatus, setCopyStatus] = useState(false);
  const copyCode = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 3000);
  };

  return (
    <Layout title="Design Tokens">
      <section className="ui segment vertical masthead">
        <h1 className="ui header">
          Color Palettes
          <p className="sub header">
            We have 4 different color pallets, Aldea, Kinedu Classrooms, Kinedu Rebrand and Miscellaneous.
          </p>
        </h1>
      </section>
      <Transition visible={copyStatus} animation="fade" duration={500}>
        <div className="ui success message">Code successfully copied</div>
      </Transition>
      <div>
        <h2 class="ui header">Primary Colors</h2>
        <div className="color-container">
          {primaryList.map((color) => (
            <ColorItem color={color} handleCopy={copyCode} />
          ))}
        </div>
        <h2 class="ui header">Secondary Colors</h2>
        <div className="color-container">
          {secondaryList.map((color) => (
            <ColorItem color={color} />
          ))}
        </div>
        <h2 class="ui header">Neutral Colors</h2>
        <div className="color-container">
          {neutralList.map((color) => (
            <ColorItem color={color} />
          ))}
        </div>
        <h2 class="ui header">General Colors</h2>
        <div className="color-container">
          {generalList.map((color) => (
            <ColorItem color={color} />
          ))}
        </div>
        <h2 class="ui header">Misc Colors</h2>
        <div className="color-container">
          {miscList.map((color) => (
            <ColorItem color={color} />
          ))}
        </div>
        <h2 class="ui header">Aldea Colors</h2>
        <div className="color-container">
          {aldeaList.map((color) => (
            <ColorItem color={color} />
          ))}
        </div>
      </div>
    </Layout>
  );
}
